<template>
  <div>
    <!-- 学生报名信息 -->
    <div class="container">

      <div class="notice-box">
        <!-- <div class="go-back">
          <div class="go-back-box" @click="goBack" >
            <i class="el-icon-d-arrow-left" />
            返回
          </div>
        </div> -->

        <div class="notice-title">
          我的报名信息
        </div>

        <div>
          <div v-if="infolist_length>0" class="info-form">
            <el-table
              v-loading="listLoading"
              :data="infolist"
              element-loading-text="Loading"
              border
              fit
              highlight-current-row
            >
              <!-- <el-table-column label="参赛赛区" width="80">
                北京
              </el-table-column> -->
              <el-table-column label="赛道" width="70">
                <template slot-scope="scope">
                  {{ scope.row.raceTrackName }}
                </template>
              </el-table-column>
              <el-table-column label="参赛类别" width="100">
                <template slot-scope="scope">
                  {{ scope.row.category }}
                </template>
              </el-table-column>
              <el-table-column label="参赛人员" width="120">
                <template slot-scope="scope">
                  <div class="cell-name">
                    {{ scope.row.realName }}
                  </div>

                </template>
              </el-table-column>
              <el-table-column label="参赛作品名称">
                <template slot-scope="scope">
                  {{ scope.row.workName }}
                </template>
              </el-table-column>
              <!-- <el-table-column label="作品封面" width="80">
                <template slot-scope="scope">
                  <el-button type="text" @click="onShowCover(scope.row.workCover)">点击预览</el-button>
                </template>
              </el-table-column> -->
              <el-table-column label="报名时间" width="120">
                <template slot-scope="scope">
                  {{ scope.row.signupTime }}
                </template>
              </el-table-column>
              <el-table-column label="报名状态" width="90">
                <!-- <template> -->
                <div class="suc-status">
                  报名成功
                </div>
                <!-- </template> -->
              </el-table-column>
              <el-table-column label="操作" width="110">
                <template slot-scope="scope">
                  <div class="op-item" @click="onShowDetail(scope.row.workId)">
                    查看详情
                  </div>
                  <!-- <div>
                    <el-button type="text" @click="onDownloadCover(scope.row.workId)">下载平面版图</el-button>
                  </div> -->
                  <div class="op-item" @click="onDownloadApplyForm(scope.row.registrationForm)">
                    下载报名表
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="修改" width="50">
                <template slot-scope="scope">
                  <div class="icon-box" @click="onEditEnrollInfo(scope.row.workId)">
                    <i class="el-icon-edit" />
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="删除" width="50">
                <template slot-scope="scope">
                  <div class="icon-box" @click="onDeleteWork(scope.row.workId,scope.$index)">
                    <i class="el-icon-delete" />
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>

        </div>

        <div class="btn-place">
          <el-button type="primary" @click="addNewWork">提交新作品</el-button>
          <!-- <div class="notice-text">
            * 暂不支持修改已报名的作品信息，您可删除之后重新提交报名信息
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs'
import { retrieveUserEnrollInfo, isExist } from '../../api/contest'
import { deleteWork } from '../../api/work'
import { pr } from '../../utils/preview'
export default {
  data() {
    return {
      id: '', // contestid
      infolist: [{
        workId: 1,
        raceTrackName: '主赛道',
        category: '文化与社会创新',
        realName: '李丽丽, 李丽丽, 李丽丽, 李丽丽, 李丽丽',
        workName: '基于内蒙古马文华的万马奔腾水杯',
        workCover: 'https://ss0.bdstatic.com/70cFuHSh_Q1YnxGkpoWK1HF6hhy/it/u=312618415,2203139709&fm=26&gp=0.jpg',
        signupTime: '2021-02-04T21:50:00',
        workStatus: 1, // 报名成功
        registrationForm: ''
      }],
      infolist_length: 3,
      listLoading: false

    }
  },
  created() {
    this.infolist = []
  },
  mounted() {
    this.id = this.$route.params.id
    this.fetchSignUpInfo()
  },
  methods: {
    // 返回到contestinfo界面
    goBack() {
      this.$router.push('/mycontest/contestinfo')
    },
    // 获取学生报名信息列表
    fetchSignUpInfo() {
      retrieveUserEnrollInfo(this.globalGetUsername(), this.id).then(Response => {
        this.infolist = Response.data.map(function(item) {
          item.signupTime = dayjs(item.signupTime).format('YYYY-MM-DD').toString()
          return item
        })
      })
    },
    // 查看作品详情
    onShowDetail(workId) {
      this.$router.push('/mycontest/enrollcheck/check/' + this.id + '/' + workId)
    },
    // 下载报名表
    onDownloadApplyForm(registrationForm) {
      var flag = false; var count = 3
      const _this = this
      const interval = setInterval(async function() {
        if (flag === false && count <= 0) { _this.$message.error('错误请联系管理员') }
        if (count <= 0 || flag) { clearInterval(interval); return }
        await isExist(registrationForm).then((Response) => {
          if (Response.data) { flag = true; window.open(pr.previewOrigin(registrationForm)) } else { count-- }
        })
      }, 1000)
    },
    // 修改作品的报名信息
    onEditEnrollInfo(workId) {
      this.$router.push('/mycontest/enrollcheck/edit/' + this.id + '/' + workId)
    },
    // 删除该条作品信息
    onDeleteWork(workId, index) {
      this.$confirm('此操作将删除该作品, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteWork(workId).then(res => {
          this.infolist.splice(index, 1)
          this.$message.success('删除作品成功')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 提交新作品
    addNewWork() {
      if (this.infolist.length < 3) {
        this.$router.push(`/enroll/${this.id}`)
      } else {
        this.$message.error('一次赛事每人最多提交3个作品')
      }
    }
  }
}
</script>

<style scoped>
@import '~@/styles/theme.css';

.border {
  border: 1px solid red;
  height: 30px;
}

.container {
  width: 100%;
  min-height: calc(100vh - 60px);
  background-color: var(--background-light-color);
  /* border: 1px solid red; */
  text-align: center;
}

.notice-box {
  position: relative;
  display: inline-block;
  background-color: #fff;
  border-radius: 10px;
  margin: auto;
  margin-top: 40px;
  height: 610px;
  width: 1000px;
  /* border: 1px solid red; */
}

.btn-place {
  position: absolute;
  width: 100%;
  bottom: 50px;
}

.go-back {
  width: 90%;
  margin: auto;
  margin-top: 15px;
  text-align: left;
  /* padding-left: 20px; */
  font-size: 14px;
}

.go-back-box {
  display: inline-block;
}

.go-back-box:hover {
  cursor: pointer;
}

.notice-title {
  text-align: center;
  margin-top: 10px;
  height: 80px;
  /* border: 1px solid red; */
  font-size: 20px;
  line-height: 80px;
}

.info-form {
  width: 90%;
  margin: auto;
  margin-top: 10px;
  /* border: 1px solid rgb(218, 214, 214); */
}

.cell-name {
  /* max-height: 50px; */
  text-align: center;
  /*1. 先强制一行内显示文本*/
  white-space: nowrap; /*默认 normal 自动换行*/
  /*2. 超出的部分隐藏*/
  overflow: hidden;
  /*3. 文字用省略号代替超出的部分*/
  text-overflow: ellipsis;
}

.op-item {
  height: 30px;
  color: #000000 !important;
  font-weight: 400;
}

.op-item:hover {
  cursor: pointer;
  font-weight: 800;
}

.icon-box {
  text-align: center;
}

.icon-box:hover {
  cursor: pointer;
  color: var(--theme-color);
}

.suc-status {
  font-weight: 800;
  color: rgb(48, 202, 61);
}

.notice-text {
  color: #a7a5a5;
  font-size: 12px;
  margin-top: 10px;
}

/* .suc-status {
  font-weight: 800;
  color: rgb(48, 202, 61);
} */
</style>

<style>
.el-table .el-table__header-wrapper .cell {
  color: #222222;
  font-weight: 600;
}

</style>
